@import-normalize;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.cattle-event-icon-container {
  background: transparent;
  border: none;
  position: relative;
}

/**
* Date range selector hacks. The component uses bootstrap for styling, which breaks
* everything if imported. These hacks make the component look "ok"
 */
#DateRangePickerContainer {
  flex: 1;
}

#DateTimeInput_start,
#DateTimeInput_end {
  width: 95%;
  text-align: center;
}

#DateRangePickerChildren {
  width: 100%;
}

.leaflet-pixi-overlay {
  z-index: 99999999;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

input[type='file']::file-selector-button {
  margin-right: 20px;
  border: none;
  background: black;
  padding: 10px 20px;
  border-radius: 6px;
  color: white;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif;
  font-weight: 500;
  letter-spacing: 0.02857em;
}

input[type='file']::file-selector-button:hover {
  background: dimgrey;
}

/**
 * Remove the up/down buttons from number inputs.
 */
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
